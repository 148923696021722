<!--信息管理表格  -->
<template>
  <div class="">
    <!-- table模块 -->
    <!-- 根据tab或者menu的切换只改变表格api的写法关键点就是表格的key要和activeTab的值一致 -->
    <ax-table
      ref="tableBox"
      :columns="columns"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      :showToolBar="false"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :dynamicSlotNames="['currentAreaCode', 'ageRange']"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
    <!-- 信息发布详情弹窗 -->
     <watch-information ref="information" @refsh="refsh"></watch-information>
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "发布人",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号/联系电话" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属分类",
    type: "select",
    model: "gender",
    options: {
      options: [
        { label: "男", value: "男" },
        { label: "女", value: "女" },
      ],
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "是否屏蔽",
    type: "select",
    model: "politicalStatus",
    options: {},
    formItem: {},
    col: { span: 6 },
  }
];

import api from "./api";
import watchInformation from './watchInformation.vue';

export default {
  components: { watchInformation },
  //import引入的组件需要注入到对象中才能使用
  // components: {  AddPeople, WatchPeople, Inports },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      activeTab: ["1"],
      visible: false,
      activeTabIndex: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "分类",
            dataIndex: "residentName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "内容",
            dataIndex: "gender",
            ellipsis: false,
            width: 80,
          },
          {
            title: "网格",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 190,
          },
          {
            title: "发布人",
            dataIndex: "age",
            ellipsis: false,
            width: 70,
          },
          {
            title: "发布时间",
            dataIndex: "nation_dictText",
            ellipsis: true,
            width: 100,
          },

          {
            title: "阅读数",
            dataIndex: "educationalLevel_dictText",
            ellipsis: false,
            width: 120,
          },
          {
            title: "点赞数",
            dataIndex: "politicalStatus_dictText",
            ellipsis: true,
            width: 150,
          },

          {
            title: "回复数",
            dataIndex: "maritalStatus_dictText",
            ellipsis: false,
            width: 100,
          },
          {
            title: "是否屏蔽",
            dataIndex: "job_dictText",
            ellipsis: false,
            width: 120,
          }
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除当前内容?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      toolActions: [
      { name: "add", text: "新增居民" },
        { name: "delect", text: "批量删除", type: "#F95A5A" },
        { name: "inports", text: "导入", type: "#67C23A" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
      currentAreaCode: "",
      currentArea: "",
      ageStart: "",
      ageEnd: "",
      currentAreaCode1: [],
      cascaderVisible: true, // 控制级联选择器的显示/隐藏
      type: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    activeTab(newValue) {
      console.log(newValue);
      if (newValue[0] === "1") {
        this.dataSourceApis = api.list;
        this.type = "0";
        this.dataSourceParams = { type: "0" };
      } else if (newValue[0] === "2") {
        this.type = "1";
        this.dataSourceApis = api.list;
        this.dataSourceParams = { type: "1" };
      }
      // 在这里更新了数据源后，如果您的表格组件正确地绑定了 dataSourceApi，它应该会自动重新渲染以显示新的数据。
    },
  },
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "watch":
          // record.title = "查看居民信息";
          this.$refs.information.openModal(record);
          break;
        // 详情
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteResidentArchivesById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteResidentArchivesByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 政治面貌
      await api.dictData({ dicKind: "political_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "politicalStatus",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    // this.getDictionsary();
    // this.getRegionInfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>