<!-- 编辑社区简介 -->
<template>
  <el-dialog
    title="查看信息发布详情"
    :visible.sync="visible"
    width="50%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <!-- 走访信息 -->
    <a-list bordered style="margin: 0; border: 0">
      <a-list-item>
        <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;">
          <a-descriptions-item label="" :span="3">
            <div style="color: #2d3138; font-size: 0.08rem">
              联系方式是否真实准确：主体负责人固定电话及手机号码、网站负责人固定电话及手机号码需为备案本人且电话有效可拨备案真实性核验材料是否真实完整：包括网站主办者身份证件、组织机构代码证、工商营业执照、核验照片等。
            </div>

            <img
              v-for="i in 10"
              src="../../../../../../assets/images/interviewInfo.png"
              style="width: 12%; height: 12%"
              alt=""
            />
          </a-descriptions-item>
        </a-descriptions>
      </a-list-item>
      <div
        slot="header"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
        :headStyle="{ backgroundColor: '#FAFAFA' }"
      >
        <div style="display: flex; align-items: center; width: 30%">
          <img
            src="../../../../../../assets/images/interviewInfo.png"
            style="width: 20%; height: 20%"
            alt=""
          />
          <div
            style="
              font-size: 0.08rem;
              font-weight: bold;
              margin-left: 2%;
              color: #2d3138;
            "
          >
            {{ residentName }}
          </div>
        </div>
        <div style="font-size: 0.06rem; color: #7d8da6">
          {{ interviewInfoList.interviewTime }}
        </div>
      </div>
    </a-list>
    <div class="icons">
      <div>
        <a-icon type="eye" />
        <div class="text" style="">{{num}}</div>
      </div>
      <div @click="showComments = !showComments">
        <a-icon type="message" />
        <div class="text">{{num}}</div>
      </div>
      <div>
        <a-icon type="like" />
        <div class="text">{{num}}+</div>
      </div>
    </div>
    <div class="comments" v-if="showComments">
      <div class="comments-info" v-for="i in 10">
        <div style="display: flex; width: 100%">
          <div class="name">{{ residentName }}:</div>
          <div class="text">不错不错</div>
        </div>
        <a-popconfirm
          title="确认删除该条评论?"
          ok-text="确认"
          cancel-text="取消"
          @confirm="confirm"
          @cancel="cancel"
        >
          <a-icon
            type="close"
            @click="deleteComment"
            style="float: right; margin-right: 1%"
          />
        </a-popconfirm>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import api from "./api";
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      saveType: false,
      showComments: false,
      residentName: "张三",
      num: 2000,
      interviewInfoList: {
        interviewTime: "2024-05-21",
      },
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    // this.owningGrid();
  },
  methods: {
    openModal() {
      this.visible = true;
      this.showComments = false;
    },
    deleteComment() {
      console.log(1111111111111);
    },
    confirm(e) {
      console.log(e);
      this.$message.success("Click on Yes");
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Click on No");
    },
    //关闭弹窗
    closeDialog() {
      this.visible = false;
      this.$emit("refsh");
    },
  },
};
</script>
    
<style lang="less" scoped>
.icons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1%;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 1%;
    width: 8%;
    &:nth-child(3) {
      margin-right: 0;
    }
    i {
      color: #a0a2b2;
      width: 20%;
    }
    .text {
      margin-right: 20%;
      color: #a0a2b2;
      width: 80%;
    }
  }
}
.comments {
height: 20vh;
  overflow-y: auto;
  .comments-info {
    height: 40px;
    background-color: #f3f7fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 93%;
    margin: 1% 0;
    margin-left: 7%;
    margin-right: 0%;
    .name {
      margin-left: 1%;
      color: #2a5cff;
    }
    .text {
      margin-left: 1%;
      color: #2d3138;
    }
  }
}
/* 穿透区域 */
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__body {
  padding: 10px 40px;
}
/deep/.ant-list-split .ant-list-header {
  border: 0;
  padding: 0;
}
/deep/.ant-list-bordered .ant-list-item {
  padding-left: 70px;
  padding-top: 0;
}
/deep/.ant-descriptions-item > span {
  width: 100%;
  height: 20%;
}
/deep/.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding: 0% !important;
}
</style>
    